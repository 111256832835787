<template>
   <span
      :class="{
         alarm: Alarm,
         fault: Fault,
         offline: Offline,
         online: Online,
         other: Other,
         blue: Blue,
         small: Small,
      }"
      class="state-dot"
   >
   </span>
</template>

<script>
export default {
   name: 'AmcDot',
   props: {
      Small: {
         type: Boolean,
         default: false,
      },
      Alarm: {
         type: Boolean,
         default: false,
      },
      Fault: {
         type: Boolean,
         default: false,
      },
      Offline: {
         type: Boolean,
         default: false,
      },
      Online: {
         type: Boolean,
         default: false,
      },
      Other: {
         type: Boolean,
         default: false,
      },
      Blue: {
         type: Boolean,
         default: false,
      },
   },
};
</script>

<style scoped>
.state-dot {
   display: inline-block;
   height: 10px;
   width: 10px;
   border-radius: 100%;
   margin-right: 12px;
}

.small {
   height: 5px;
   width: 5px;
}

.alarm {
   background-color: #ea3d2f;
}

.fault {
   background-color: #f0d961;
}

.offline {
   background-color: #f0f1f2;
}

.online {
   background-color: #2fa84f;
}

.other {
   background-color: #919294;
}

.blue {
   background-color: #137af6;
}
</style>
