var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"state-dot",class:{
      alarm: _vm.Alarm,
      fault: _vm.Fault,
      offline: _vm.Offline,
      online: _vm.Online,
      other: _vm.Other,
      blue: _vm.Blue,
      small: _vm.Small,
   }})}
var staticRenderFns = []

export { render, staticRenderFns }